import axios from "axios";
import connectionMixin from "@/mixins/connectionParams.js";
import runtimes from "@/assets/runtimes.json";

export default {
  name: "FunctionDetails",
  mixins: [connectionMixin],
  data() {
    return {
      dependency: "",
      downloadFile: "",
      downloadFileName: "",
      downloadFileDependencyUrl: "",
      successMessage: false,
      successMessageText: "",
      errorMessage: false,
      errorMessageText: "",
      logs: [],
      functionName: "",
      logLineNumber: 0,
      logLines: "",
      interval: "",
    };
  },
  mounted() {
    this.getFunctionDetails();
    this.getFunctionLogs(this.$route.params.id);
    document.addEventListener("backbutton", this.yourCallBackFunction, false);
  },
  methods: {
    getFunctionDetails() {
      axios
        .get(this.apihost + "/functions/" + this.$route.params.id, this.opts)
        .then((response) => {
          this.functionDetails = response.data;
          this.functionName = this.functionName.name;
          this.runtime_logo = this.functionDetails.kind.replace(
            /[^A-Za-z]+/g,
            ""
          );
          this.language =
            this.runtime_logo == "nodejs" ? "javascript" : this.runtime_logo;
          this.dependency = response.data.dependencies;
          if (this.dependency !== null) {
            this.downloadFileName = this.dependency.fileName;
            this.downloadFileDependencyUrl = this.dependency.dependenciesAt;
          }
          this.getTriggersFor(response.data.name);
          this.getFunctionCode();
          this.getDependency();
        })
        .catch((err) => {
          console.error("failed to get function details", err);
        });
    },

    getFunctionCode() {
      axios
        .get(
          this.apihost + "/functions/" + this.functionDetails.name + "/code",
          this.opts
        )
        .then((response) => {
          this.code = response.data;
        })
        .catch((err) => {
          console.error("failed to get function code", err);
        });
    },

    getTriggersFor(functionName) {
      axios
        .get(this.apihost + "/triggers/", this.opts)
        .then((listOfTrigger) => {
          if (listOfTrigger)
            listOfTrigger.data.forEach((trigger) => {
              if (trigger.functionName === functionName) {
                this.triggerExist = true;
                this.triggerName = trigger.name;
                this.getTriggerDetails(this.triggerName);
              }
            });
        });
    },
    getTriggerDetails(name) {
      this.activationLogEnable = false;
      axios
        .get(this.apihost + "/triggers/" + name, this.opts)
        .then((response) => {
          this.triggerDetails = response.data;
          this.host = "http://" + this.triggerDetails.host;
        });
    },
    getDependency() {

      axios
        .get(this.apihost + this.downloadFileDependencyUrl, this.opts)
        .then((response) => {
          this.dependency = JSON.stringify(response.data);
          this.dependencyLang = this.downloadFileName.split(".")[1];
        })
        .catch((err) => {
          this.handlingError(err.response.status, this.functionName);
        });
    },
    downloadDependency() {
      var fileURL = window.URL.createObjectURL(new Blob([this.dependency]), {
        type: "text/plain",
      });
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", this.downloadFileName);
      fileLink.click();
    },

    //Create the trigger for function
    createTrigger(functionName) {
      axios
        .post(
          this.apihost + "/triggers",
          { functionName: functionName },
          this.opts
        )
        .then((response) => {
          let status = response.status;
          if (status === 201) {
            this.successMessage = true;
            this.successMessageText = this.$t(
              "message.functionlist.snackbar.function_associated",
              { name: functionName }
            );
            this.getTriggersFor(functionName);
          } else {
            this.handlingError(status, functionName);
          }
        });
    },

    //Open the delete function dialog
    deleteFunctionDialog() {
      this.deleteFunctionModalOpened = true;
    },
    //Delete Functions in Serverless environment
    deleteFunction(name) {
      axios.delete(this.apihost + "/functions/" + name, this.opts).then(() => {
        this.successMessage = true;
        this.successMessageText = this.$t(
          "message.functionlist.snackbar.function_deleted",
          { name: name }
        );
        this.deleteTriggerModalOpened = false;
        this.$router.push("/");
      });
    },

    getFunctionLogs(name) {
      this.logs = [];
      this.interval = setInterval(() => {
        axios
          .get(this.apihost + "/functions/" + name + "/logs", this.opts)
          .then((response) => {
            if (response.status == 200) {
              this.logLineNumber++;
              let line;
              let loglines = response.data.split("\n");
              for (line of loglines) {
                if (!line.includes("::ffff"))
                  this.logs.push({ id: this.logLineNumber++, data: line });
              }
            }
          });
      }, 10000);
    },

    exportFunctionCode() {
      var fileURL = window.URL.createObjectURL(new Blob([this.code]), {
        type: "text/plain",
      });
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        this.functionName + runtimes[this.runtime_logo].extension
      );
      fileLink.click();
    },
  },
  beforeRouteLeave: function(to, from, next) {
    clearInterval(this.interval);
    next();
  },
};
